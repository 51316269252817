import moment from 'moment'
import { States } from "../../../common/constants/states";

class CompleteSetupController {

    constructor(FlashService, UsersModel, UserService, $rootScope){
        this.FlashService = FlashService;
        this.studentsModel = UsersModel;
        this.studentService = UserService;
        this.states = States;
        this.state = '';
        this.setupData = {}
        this.$rootScope = $rootScope;
        this.submitted = false;
        this.stateRequiresPermit = this.state === 'CA' ||
                                   this.state === 'GA' ||
                                   this.state === 'PA' ||
                                   this.state === 'MO' ||
                                   this.state === 'KY' ||
                                   this.state === 'NY' ||
                                   this.state === 'AZ' ||
                                   this.state === 'CO' ||
                                   this.state === 'OK';
    }

    userNeedsPickupLocation() {
        return this.student.pickuplocations.length === 0;
    }

    submitSetup(){
        this.submitted = true;
        let data = Object.assign({}, this.setupData);
        if (this.userNeedsPickupLocation() === true) {
            data.pickuplocation = {};
            data.pickuplocation.street = this.setupData.location.street;
            data.pickuplocation.city = this.setupData.location.city;
            data.pickuplocation.state = this.setupData.location.state;
            data.pickuplocation.zip = this.setupData.location.zip;
        }

        if (this.stateRequiresPermit === true) {
            data.permit.issue_date = moment(this.setupData.permit.issuedate, 'MMDDYYYY').format('YYYY-MM-DD');
            data.permit.expiration_date = moment(this.setupData.permit.expirationdate, 'MMDDYYYY').format('YYYY-MM-DD');
        }
        this.studentsModel.completeStudentSetup(this.student.id, data).then(response => {
            this.submitted = false;
            this.student.setup_complete = true;
            if (response.message) {
                this.FlashService.setMessage({
                    'message' : response.message,
                    'type' : 'success'
                });
            }

            let user = this.studentService.getCurrentUser();
            if( user.role.type === 'student' ) {
                user.setup_complete = true;
                this.studentService.setCurrentUser(user)
            }
            if (user.role.type !== 'student') {
                this.$rootScope.$broadcast('STUDENT_SETUP_COMPLETE');
            }
        }).catch(response => {
            this.submitted = false;
        })
    }

}

CompleteSetupController.$inject = ['FlashService', 'UsersModel', 'UserService', '$rootScope']
export default CompleteSetupController;
